import React, { useState, useEffect  } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MdOutlineEditNote, MdOutlineInventory, MdOutlineInventory2, MdOutlinePriceChange, MdOutlinePublishedWithChanges } from 'react-icons/md';
import { navbarData, sidebarData } from '../../../data';
import { Page } from '@lagoagest-lda/common-frontend-core';
import { StockMovementTable, StockCurrentCustomer } from '@lagoagest-lda/module-renting-frontend';

const RentingStockMovementPage = () => {
    const { t } = useTranslation();
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Initialize selectedEndDate with the current date
    const endDateIni = new Date();
    const [selectedEndDate, setSelectedEndDate] = useState(endDateIni);

    // Initialize selectedStartDate with one month before selectedEndDate
    const startDateIni = new Date(endDateIni.getFullYear(), endDateIni.getMonth() - 1, endDateIni.getDate());
    const [selectedStartDate, setSelectedStartDate] = useState(startDateIni);
    

    const location = useLocation();
    const currentPath = location.pathname; // Get the current pathname
    const stockMovementPath = currentPath === '/stock/movement' ? 'stockMovement' : null;

    const styles = {
        currentStockTitle: {
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "bold",
            marginTop: "25px"
        }
    };

    useEffect(() => {
        console.log('Selected Customer:', selectedCustomer);
    }, [selectedCustomer]);

    useEffect(() => {
        console.log('Selected Start Date:', selectedStartDate);
    }, [selectedStartDate]);

    useEffect(() => {
        console.log('Selected End Date:', selectedEndDate);
    }, [selectedEndDate]);

    const pageContent = (
        <div className='page-content'>
        <div className="main-content">
            <div style={styles.currentStockTitle}>
                Stock Movement
            </div>
            <StockMovementTable loadingColor='#47ab42' customer={selectedCustomer} startDate={selectedStartDate} endDate={selectedEndDate} />
        </div>
        <div className="sidebar-filter">
        <StockCurrentCustomer loadingColor='#47ab42' onStartDateSelect={setSelectedStartDate} onEndDateSelect={setSelectedEndDate} 
            onCustomerSelect={setSelectedCustomer} path={stockMovementPath} />
        </div>
    </div>
        );

    const sidebarItems = [
        {
            icon: <MdOutlinePriceChange />,
            label: t('module.renting.customerPrice.header'),
            section: 'customer/price'
        },
        {
            child: [
                {
                    icon: <MdOutlineInventory />,
                    label: t('module.renting.stockCurrent.header'),
                    section: 'stock/current'
                },
                {
                    icon: <MdOutlinePublishedWithChanges />,
                    label: t('module.renting.stockMovement.header'),
                    section: 'stock/movement'
                },
                {
                    icon: <MdOutlineEditNote />,
                    label: t('module.renting.stockManagement.header'),
                    section: 'stock/management'
                }
            ],
            icon: <MdOutlineInventory2 />,
            label: 'Stock'
        }
    ];

    const sidebar = {
        //collapsable: false,
        hasSettings: true,
        hasProfile: true,
        getCurrentUser: navbarData.getCurrentUser,
        items: sidebarItems,
        logout: navbarData.logout
        //title: 'tbd'
    }

    return <Page content={pageContent} logout={navbarData.logout} navbar={navbarData} sidebar={sidebar} />;
};

export default RentingStockMovementPage;