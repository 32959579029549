import React from 'react';
import { navbarData, footerData } from '../../data';
import { Navbar, Footer } from '@lagoagest-lda/common-frontend-core';
import HeroSection from '../../components/HeroSection';
import './inov2030.css';
import banner from './inov2030.png';
import header from './inov2030.jpg';

const Inov2030 = () => {
    return (
        <>
            <Navbar minimal={true} backgroundColor={navbarData.backgroundColor} backgroundTopColor={navbarData.backgroundTopColor} title={navbarData.title} logo={navbarData.logo}/>
            <div className='inov2030-container'>
                <img src={header} className='inov2030-img-header'/>
            </div>
            <div className='inov2030-container'>
                <h1 className='inov2030-title'>Aumento da capacidade produtiva e automatização da produção</h1>
                <div className='inov2030-context'>
                    <div className='inov2030-content'>
                        <div>O presente projeto surge da necessidade de dar resposta ao crescente aumento da procura, dotando a Neutripuro de uma maior capacidade produtiva, mais eficiente e sustentável, e alinhada com a indústria 4.0, enquanto impulsiona o alargamento e a estratégia de expansão da empresa para o mercado espanhol.</div>
                        <div>A Neutripuro irá deslocalizar as suas instalações para uma unidade industrial com maior área produtiva, onde serão instalados novos equipamentos mais modernos a fim de alcançar processos mais eficientes, ecológicos e sustentáveis.</div>
                        <div>A alteração de localização possibilita não só o aumento da capacidade produtiva mas também uma melhor organização e conjugação das áreas, equipamentos e processos, desde a entrada até à saída das peças de roupa.</div>
                        <div>Para além das máquinas produtivas a empresa irá também implementar um Sistema Integrado de Produção e instalar um Sistema Solar Fotovoltaico para autoconsumo.</div>
                    </div>
                    <div className='inov2030-values'>
                        <div className='inov2030-values-highlight'>Investimento total</div>
                        <div>1.324.623,00 €</div>
                        <div className='inov2030-values-highlight'>Apoio financeiro da união europeia</div>
                        <div>529.849,20 €</div>
                    </div>
                </div>
            </div>
            <div className='inov2030-container'>
                <img src={banner} className='inov2030-img-footer'/>
            </div>
            <Footer minimal={true} logo={footerData.logo} title={footerData.title}/>
        </>
    );
};

export default Inov2030;