import React from 'react';
import { useTranslation } from 'react-i18next';
import IconWashing from '../../images/service-washing.svg';
import IconTreatment from '../../images/service-treatment.svg';
import IconRenting from '../../images/service-renting.svg';
import './service.css';

const Services = () => {
    const { t } = useTranslation();
    return (
        <div id="services" className='services-container'>
            <h1 className='services-title'>{t('app.services')}</h1>
            <div className='services-values'>
                <div className='services-card'>
                    <img className='services-card-icon' src={IconWashing} alt='' />
                    <h2 className='services-card-title'>{t('app.services1Tittle')}</h2>
                    <p className='services-card-content'>{t('app.services1Content')}</p>
                </div>
                <div className='services-card'>
                    <img className='services-card-icon' src={IconTreatment} alt='' />
                    <h2 className='services-card-title'>{t('app.services2Tittle')}</h2>
                    <p className='services-card-content'>{t('app.services2Content')}</p>
                </div>
                <div className='services-card'>
                    <img className='services-card-icon' src={IconRenting} alt='' />
                    <h2 className='services-card-title'>{t('app.services3Tittle')}</h2>
                    <p className='services-card-content'>{t('app.services3Content')}</p>
                </div>
            </div>
        </div>
    )
}

export default Services;