import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon1 from '../../images/process-1-pickup.jpg';
import Icon2 from '../../images/process-2-sorting.jpg';
import Icon3 from '../../images/process-3-washing.jpg';
import Icon4 from '../../images/process-4-drying.jpg';
import Icon5 from '../../images/process-5-folding.jpg';
import Icon6 from '../../images/process-6-quality.jpg';
import Icon7 from '../../images/process-7-delivery.jpg';
import './process.css';

const Process = () => {
    const { t } = useTranslation();
    return (
        <div id='process' className='process-container'>
            <h1 className='process-title'>{t('app.process')}</h1>
            <div className="process-steps">
                <div className="process-center-line" />
                <div className="process-step process-step-left">
                    <section>
                        <img className='process-step-icon process-step-icon-left' src={Icon1} alt='' />
                        <span className="process-step-title">{t('app.process1Tittle')}</span>
                        <p className="process-step-content">{t('app.process1Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section>
                        <img className='process-step-icon process-step-icon-right' src={Icon2} alt='' />
                        <span className="process-step-title">{t('app.process2Tittle')}</span>
                        <p className="process-step-content">{t('app.process2Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section>
                        <img className='process-step-icon process-step-icon-left' src={Icon3} alt='' />
                        <span className="process-step-title">{t('app.process3Tittle')}</span>
                        <p className="process-step-content">{t('app.process3Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section>
                        <img className='process-step-icon process-step-icon-right' src={Icon4} alt='' />
                        <span className="process-step-title">{t('app.process4Tittle')}</span>
                        <p className="process-step-content">{t('app.process4Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section>
                        <img className='process-step-icon process-step-icon-left' src={Icon5} alt='' />
                        <span className="process-step-title">{t('app.process5Tittle')}</span>
                        <p className="process-step-content">{t('app.process5Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-right">
                    <section>
                        <img className='process-step-icon process-step-icon-right' src={Icon6} alt='' />
                        <span className="process-step-title">{t('app.process6Tittle')}</span>
                        <p className="process-step-content">{t('app.process6Content')}</p>
                    </section>
                </div>
                <div className="process-step process-step-left">
                    <section>
                        <img className='process-step-icon process-step-icon-left' src={Icon7} alt='' />
                        <span className="process-step-title">{t('app.process7Tittle')}</span>
                        <p className="process-step-content">{t('app.process7Content')}</p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Process;