import React from 'react';
import './heroSection.css';
import Logo from '../../images/hero-logo.png';
import Background from '../../images/hero-background.png';

const HeroSection = () => {
    return (
        <div className='hero-container'>
            <img className='hero-background' src={Background} alt='' />
            <div className='hero-content'>
                <img className='hero-icon' src={Logo} alt='' />
            </div>
        </div>
    );
};

export default HeroSection;