import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MdOutlineEditNote, MdOutlineInventory, MdOutlineInventory2, MdOutlinePriceChange, MdOutlinePublishedWithChanges } from 'react-icons/md';
import { navbarData, sidebarData } from '../../../data';
import { Page } from '@lagoagest-lda/common-frontend-core';
import { StockManagementTable, StockCurrentCustomer } from '@lagoagest-lda/module-renting-frontend';

const RentingStockManagementPage = () => {
    const { t } = useTranslation();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedPermanent, setSelectedPermanent] = useState(null);


    const location = useLocation();
    const currentPath = location.pathname; // Get the current pathname
    const managementStockPath = currentPath === '/stock/management' ? 'stockManagement' : null;

    const styles = {
        currentStockTitle: {
            textAlign: "center",
            fontSize: "36px",
            fontWeight: "bold",
            marginTop: "25px"
        }
    };

    const pageContent = (
        <div className='page-content'>
            <div className="main-content">
                <div style={styles.currentStockTitle}>
                    Stock Management
                </div>
                <StockManagementTable loadingColor='#47ab42' permanent={selectedPermanent} customer={selectedCustomer} />
            </div>
            <div className="sidebar-filter">
                <StockCurrentCustomer loadingColor='#47ab42' onCustomerSelect={setSelectedCustomer} onPermanentSelect={setSelectedPermanent} path={managementStockPath} />
            </div>
        </div>
    );

    const sidebarItems = [
        {
            icon: <MdOutlinePriceChange />,
            label: t('module.renting.customerPrice.header'),
            section: 'customer/price'
        },
        {
            child: [
                {
                    icon: <MdOutlineInventory />,
                    label: t('module.renting.stockCurrent.header'),
                    section: 'stock/current'
                },
                {
                    icon: <MdOutlinePublishedWithChanges />,
                    label: t('module.renting.stockMovement.header'),
                    section: 'stock/movement'
                },
                {
                    icon: <MdOutlineEditNote />,
                    label: t('module.renting.stockManagement.header'),
                    section: 'stock/management'
                }
            ],
            icon: <MdOutlineInventory2 />,
            label: 'Stock'
        }
    ];

    const sidebar = {
        //collapsable: false,
        hasSettings: true,
        hasProfile: true,
        getCurrentUser: navbarData.getCurrentUser,
        items: sidebarItems,
        logout: navbarData.logout
        //title: 'tbd'
    }

    return <Page content={pageContent} logout={navbarData.logout} navbar={navbarData} sidebar={sidebar} />;
};

export default RentingStockManagementPage;