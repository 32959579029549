import React from 'react';
import { useTranslation } from 'react-i18next';
import IconRigor from '../../images/about-rigor.svg';
import IconCommitment from '../../images/about-commitment.svg';
import IconQuality from '../../images/about-quality.svg';
import IconInnovation from '../../images/about-innovation.svg';
import './about.css';

const About = () => {
    const { t } = useTranslation();
    return (
        <div id="about" className='about-container'>
            <h1 className='about-title'>{t('app.about')}</h1>
            <div className='about-content'>{t('app.about1')}</div>
            <div className='about-content-highlights'>{t('app.about2')}</div>
            <div className='about-content'>{t('app.about3')}</div>

            <div className='about-key-values'>
                <div className='about-key-value'>
                    <img className='about-key-value-icon' src={IconRigor} alt='' />
                    <h2 className='about-key-value-title'>{t('app.about4Tittle')}</h2>
                    <p className='about-key-value-content'>{t('app.about4Content')}</p>
                </div>
                <div className='about-key-value'>
                    <img className='about-key-value-icon' src={IconCommitment} alt='' />
                    <h2 className='about-key-value-title'>{t('app.about5Tittle')}</h2>
                    <p className='about-key-value-content'>{t('app.about5Content')}</p>
                </div>
                <div className='about-key-value'>
                    <img className='about-key-value-icon' src={IconQuality} alt='' />
                    <h2 className='about-key-value-title'>{t('app.about6Tittle')}</h2>
                    <p className='about-key-value-content'>{t('app.about6Content')}</p>
                </div>
                <div className='about-key-value'>
                    <img className='about-key-value-icon' src={IconInnovation} alt='' />
                    <h2 className='about-key-value-title'>{t('app.about7Tittle')}</h2>
                    <p className='about-key-value-content'>{t('app.about7Content')}</p>
                </div>
            </div>
        </div>
    )
}

export default About;