import React from 'react';
import { useNavigate } from 'react-router-dom';
import { navbarData, footerData } from '../data';
import { AuthenticationPage } from '@lagoagest-lda/common-frontend-authentication';
import { Navbar, Footer } from '@lagoagest-lda/common-frontend-core';

const LoginPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <Navbar backgroundColor={navbarData.backgroundColor} backgroundTopColor={navbarData.backgroundTopColor} logo={navbarData.logo}
                minimal={true} title={navbarData.title} navigate={navigate} />
            <div className='content-minimal'><AuthenticationPage redirect='/profile' navigate={navigate} /></div>
        </>
    );
};

export default LoginPage;